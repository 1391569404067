import React, { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  VStack,
  StackDivider,
  Skeleton,
} from '@chakra-ui/react';
import {
  WIDGET_CHART_ID,
  WIDGET_WORKSPACE_MODE,
} from '../../../../utils/widget-constants';
import { useDashboardContext } from '../../../../context/dashboard.context';
import WidgetItem from '../../../../components/dashboard/widget';
import { AiOutlineLineChart } from 'react-icons/ai';
import { useLoadWidgets } from '../../../../hooks/dashboard.hooks';
import { dashboardConst } from '../../../../utils/action.constant';
import WidgetSIPView from '../widget-sip-view';
import WidgetSIPDynamic2View from '../widget-kpi-dynamic';
import WidgetSIPPayoutViewSA from '../widget-sip-view-SA';
import WidgetSIPDynamicViewSME from '../widget-sip-view-SME';
import WidgetSIPDynamicViewSA from '../widget-sip-view-SA/payout';
import WidgetSIPPayourViewSME from '../widget-sip-view-SME/payout';
import WidgetSIPPayoutView from '../widget-sip-view/payout';
import WidgetPenUtlView from '../widget-pen-utl';
import WidgetLeaderBoard from '../widget-leaderboard';
import WidgetTopDownView from '../widgetTopDownBottomUp';
import WidgetBottomUpView from '../widgetTopDownBottomUp/bottomUp';
import WidgetPeriodicSummary from '../widget-periodic-summary';
import WidgetAccountView from '../widget-account-view';
import TataPlaySummeryView from '../widget-tataPlay-view';

const widgetComponent = {
  220: WidgetPenUtlView,
  221: WidgetLeaderBoard,
  223: WidgetBottomUpView,
  224: WidgetTopDownView,
  148: WidgetSIPView,
  1931: WidgetSIPDynamic2View,
  219: WidgetSIPPayoutView,
  222: WidgetPeriodicSummary,
  1778: WidgetAccountView,
  2103: TataPlaySummeryView,
  1932: WidgetSIPPayoutViewSA,//sip payout sa
  1933: WidgetSIPDynamicViewSA,//sipDynamic sa
  1934: WidgetSIPPayourViewSME,//sip payout sme
  1935: WidgetSIPDynamicViewSME//sip dynamic SME
};

function WidgetView({ onWidgetViewChange }) {
  const {
    state: { widgetList, queryFilters },
    dispatch: dashboardDispatch,
  } = useDashboardContext();

  const { mutate: loadWidgetList, isLoading } = useLoadWidgets();
  const [loader,setLoader] = useState(true)
  const isMobileView = localStorage.getItem("IS_MOBILE_VIEW")
  useDeepCompareEffect(() => { // it compares the values of two objects rahter than comparing referrence equality.    
    if (
      !!queryFilters.selectedSubApplication &&
      queryFilters.selectedSubApplication.value !== 148
    ) {
      loadWidgetList(
        {
          tag_type: queryFilters.selectedSubApplication,
          creator: queryFilters.selectedCreator,
          filter_organization_id: queryFilters.selectedOrganization?.value,
        },
        {
          onSuccess: data => {
            setWidgetList(data);
            setLoader(false)
            onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_VIEW);
          },
        }
      );
    }
    return () => {
      clearWidgetList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ {dummyKey:"dummyData"}, //dummy obj as a dependency for useDeepCompareEffect bcoz initially all other dependencies are undefined/primitive data types. 
    queryFilters.selectedApplication,
    queryFilters.selectedSubApplication,
    queryFilters.selectedCluster,
    queryFilters.selectedBuilding,
    queryFilters.selectedWorkforce,
    queryFilters.selectedCreator,
    // queryFilters.selectedCampaign,
    queryFilters.selectedSegment,
    queryFilters.selectedWorkforceTag,
    queryFilters.selectedOpportunity,
    queryFilters.selectedCategory,
    queryFilters.selectedFamily,
    queryFilters.selectedProduct,
    queryFilters.selectedAccount,
    queryFilters.selectedDateType,
    queryFilters.selectedStatus,
    queryFilters.selectedSubstatus,
    queryFilters.selectedTimescale,
    queryFilters.startDateTime,
    queryFilters.endDateTime,
    queryFilters.selectedField1,
    queryFilters.selectedField2,
    queryFilters.selectedField3,
    queryFilters.selectedField4,
    queryFilters.selectedField5,
    queryFilters.selectedTag1,
    queryFilters.selectedTag2,
    queryFilters.selectedTag3,
    queryFilters.selectedTagType1,
    queryFilters.selectedTagType2,
    queryFilters.selectedTagType3,
    queryFilters.selectedOrganization,
    queryFilters.selectedWorkflow,
    queryFilters.selectedIndustry,
    queryFilters.selectedCustomer,
    queryFilters.selectedPeriod,
  ]);

  function setWidgetList(widgetList) {
    return dashboardDispatch({
      type: dashboardConst.LOAD_WIDGETS,
      widgetList,
    });
  }

  function clearWidgetList() {
    return dashboardDispatch({
      type: dashboardConst.CLEAR_WIDGETS,
    });
  }

  function getColSpan(widgetInfo) {
    const { widget_chart_id, filter_tag_type_id } = widgetInfo;

    try {
      if (
        [WIDGET_CHART_ID.PIE_CHART, WIDGET_CHART_ID.NUMERIC_CHART].includes(
          widget_chart_id
        ) === false ||
        (filter_tag_type_id.length &&
          [1770, 1779].includes(JSON.parse(filter_tag_type_id)[0].tag_type_id))
      )
        return 12;
      else if (
        [WIDGET_CHART_ID.NUMERIC_CHART].includes(widget_chart_id) === true
      )
        return 3;
      else return 6;
    } catch (error) {
      console.error(error);
    }
  }

  let Component = widgetComponent[queryFilters.selectedSubApplication?.value];
  const widgetMainView = Component ? (
    <Component />
  ) : !widgetList || isLoading || loader? (
    <Grid h="100%" w="100%" templateColumns="repeat(12, 1fr)" gap={3}>
      {[1, 2, 3, 4, 5, 6].map((item, i) => (
        <GridItem
          key={item}
          colSpan={[0, 1, 2].includes(i) ? 4 : [3, 4].includes(i) ? 6 : 12}
        >
          <Skeleton key={i} minH="200px" />
        </GridItem>
      ))}
    </Grid>
  ) : widgetList.length ? (
    !isMobileView ? 
    <Grid h="100%" w="100%" templateColumns="repeat(12, 1fr)" gap={3}>
      {widgetList.map((widget, i) => {
        const widgetInlineData = JSON.parse(widget.activity_inline_data);
        let widgetInfo = widgetInlineData.widget_info;
        // const { widget_chart_id } = widgetInfo;
        return (
          <GridItem
            key={i}
            colSpan={getColSpan(widgetInfo)}
            // colSpan={
            //   [
            //     WIDGET_CHART_ID.PIE_CHART,
            //     WIDGET_CHART_ID.NUMERIC_CHART,
            //   ].includes(widget_chart_id)
            //     ? 6
            //     : 12
            // }
          >
            <WidgetItem
              key={widget.widget_id}
              widgetData={widget}
              widgetInlineData={widgetInfo}
              queryFilters={queryFilters}
              onWidgetViewChange={onWidgetViewChange}
            />
          </GridItem>
        );
      })}
    </Grid> 
    :  <Box h="100%" w="100%" templateColumns="repeat(12, 1fr)" gap={3}>
    {widgetList.map((widget, i) => {
      const widgetInlineData = JSON.parse(widget.activity_inline_data);
      let widgetInfo = widgetInlineData.widget_info;
      // const { widget_chart_id } = widgetInfo;
      return (
        <GridItem
          key={i}
          colSpan={getColSpan(widgetInfo)}
          // colSpan={
          //   [
          //     WIDGET_CHART_ID.PIE_CHART,
          //     WIDGET_CHART_ID.NUMERIC_CHART,
          //   ].includes(widget_chart_id)
          //     ? 6
          //     : 12
          // }
        >
          <WidgetItem
            key={widget.widget_id}
            widgetData={widget}
            widgetInlineData={widgetInfo}
            queryFilters={queryFilters}
            onWidgetViewChange={onWidgetViewChange}
          />
        </GridItem>
      );
    })}
  </Box>
  ) : (!loader&&!widgetList.length)?(
    <Center>
      <VStack
        divider={<StackDivider borderColor="brand.800" />}
        spacing={4}
        align="center"
      >
        <Heading as="h3" size="lg" color="brand.800">
          No widgets available
        </Heading>
        <AiOutlineLineChart color={localStorage.getItem('color')} size="30vh" />
      </VStack>
    </Center>
  ):null;

  return <Box minW="fit-content">{widgetMainView}</Box>;
}

export default WidgetView;
