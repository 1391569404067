/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useAuthContext } from '../../../../../context/auth.context'
import { useDashboardContext } from '../../../../../context/dashboard.context'
import { dashboardConst } from '../../../../../utils/action.constant'
import {
  useLoadAssetChannels,
  useLoadPayoutAssetReportees,
  useLoadManagerDetails,
  useLoadSIPPayoutWidgets,
} from '../../../../../hooks/dashboard.hooks'
import {
  Box,
  Divider,
  Flex,
  Icon,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import DrillDownTable, {
  TH,
  TR,
  TD,
} from '../../../../../components/dashboard-table'
import ReactExport from 'react-data-export'
import { BiDownload } from 'react-icons/bi'
import { isRoleAccount, isRoleSelf } from '../../../../../config/roles.config'
import { calculateStartEndDate } from '../../../../../utils/dashboard.utils'
import { sectionDetailedTrack } from '../../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../../repository/repo.utils'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function WidgetSIPPayoutView() {
  const {
    state: { queryFilters, sipReporteeList },
    dispatch: dashboardDispatch,
  } = useDashboardContext()
  const {
    state: { authData },
  } = useAuthContext()
  const [download, setDownload] = useState(false)
  const {
    mutate: mutateLoadReportees,
    data: reporteeList,
    isLoading,
  } = useLoadPayoutAssetReportees()

  const {
    mutate: mutateLoadChannel,
    data: channelList,
    isLoading: isChannelLoading,
  } = useLoadAssetChannels()

  const {
    mutate: mutateLoadManagerDetails,
    data: managerDetails,
  } = useLoadManagerDetails()

  const {
    mutate: mutateLoadSIPWidget,
    data: sipWidgetList,
    isLoading: isSipWidgetDataLoading,
    isSuccess: isSipWidgetDataSuccess,
  } = useLoadSIPPayoutWidgets()

  const [isSelf, setIsSelf] = useState(true)
  const [isAccountManager, setIsAccountManager] = useState(false)

  useEffect(() => {
    return () => {
      dashboardDispatch({
        type: dashboardConst.REMOVE_SIP_RESOURCE,
      })
    }
  }, [])

  useEffect(() => {
    checkUserRole()
  }, [queryFilters])

  const checkUserRole = (manager_id = false) => {
    const { asset_type_id } = authData
    if (isRoleAccount.includes(manager_id ? manager_id : asset_type_id)) {
      setIsAccountManager(true)
    } else {
      if (isRoleSelf.includes(asset_type_id)) {
        setIsSelf(false)
      }
    }
    let manager ={}
    if(!!queryFilters && queryFilters.hasOwnProperty('selectedCreator') && queryFilters.selectedCreator?.value !== 0){
      manager = {
        operating_asset_first_name: queryFilters.selectedCreator?.operating_asset_first_name,
        asset_id : queryFilters.selectedCreator?.asset_id,
      }
    }
    else {
    const { operating_asset_first_name, asset_id } = authData
     manager = {
      operating_asset_first_name,
      asset_id,
    }
  }
    if (isAccountManager) {
      loadAccountForAsset(manager)
    } else {
      loadResourceForAsset(manager, true)
    }
  }

  const loadAccountForAsset = manager => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedYear,
    } = queryFilters
    let { startDate, endDate } = calculateStartEndDate({
      selectedPeriod,
      selectedPeriodType,
      selectedYear,
    })

    mutateLoadChannel(
      {
        segment: selectedSipSegment,
        manager,
        startDate,
        endDate,
      },
      {
        onSuccess: data => {
          if (data.length) {
            loadSIPWidgetForAsset(manager)
          }
        },
      }
    )
  }

  const loadResourceForAsset = (reportee, isManager = false) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedYear,
    } = queryFilters
    const { startDate, endDate } = calculateStartEndDate({
      selectedPeriod,
      selectedPeriodType,
      selectedYear,
    })
    mutateLoadReportees(
      { flag: 4, creator: reportee },
      {
        onSuccess: data => {
          addReporteeToStack(reportee, isManager)
          if (data.length) {
            loadSIPWidgetForAsset(reportee)
          }
        },
      }
    )
      loadSelfData(reportee.asset_id)
  }

  const loadSelfData = manager_id => {
    mutateLoadManagerDetails({ manager_id })
  }

  const loadSIPWidgetForAsset = (reportee, isAccount = false) => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedYear,
    } = queryFilters

    const { startDate, endDate } = calculateStartEndDate({
      selectedPeriod,
      selectedPeriodType,
      selectedYear,
    })

    mutateLoadSIPWidget({
      creator: reportee,
      segment: !!selectedSipSegment ? selectedSipSegment.value : 0,
      timeline: selectedPeriodType,
      period: selectedPeriod,
      year: selectedYear,
      startDate,
      endDate,
    })
  }

  const addReporteeToStack = (reportee, isManager) => {
    dashboardDispatch({
      type: isManager
        ? dashboardConst.ADD_SIP_RESOURCE
        : dashboardConst.SET_SIP_RESOURCE,
      reportee: reportee,
    })
  }

  const renderHeadings = (isSelf = false) => {
    let heads = [
      isSelf
        ? 'Self'
        : isAccountManager
        ? !!queryFilters.selectedSipSegment &&
          queryFilters.selectedSipSegment.value === 184
          ? 'Channel Name'
          : 'Account Name'
        : 'Employee Name',
      isAccountManager
        ? !!queryFilters.selectedSipSegment &&
          queryFilters.selectedSipSegment.value === 184
          ? 'Channel Code'
          : 'Account Code'
        : 'Employee Code',
      'Employee Email',
      'Role',
      'Segment',
      'Circle',
      'Cluster',
      'Vertical',
      'Employees on SIP',
      'Qualified for SIP',
      'SIP Penetration %',
      'SIP Utilization %',
      'Weighted SIP Target vs Achievement %',
    ]
    let subHeads = ['Target', 'Achievement - Final', 'Payout - Final']
      let uniqueDynamicHeads = sipWidgetList?.filter((item,inx,data)=>data.findIndex(el=>el.widget_type_id === item.widget_type_id)===inx)
    let dynamicHeads = uniqueDynamicHeads?.map((header, i) => {
      let unit,
        format = ''
      return (
        <TH rowSpan={1} colSpan={5} key={i + 1}>
          {`${header.widget_type_code} (Measurement Type - ${unit}; Formatting - ${format} )`}
        </TH>
      )
    })

    heads = heads.map(item => (
      <TH rowSpan={2} key={item}>
        {item}
      </TH>
    ))

    heads = heads.concat(dynamicHeads)
    let tableHead = (
      <>
        <TR>
          <TH rowSpan={2}>#</TH>
          {heads}
        </TR>
        <TR>
          {dynamicHeads?.map(_ =>
            subHeads.map(item => (
              <TH rowSpan={1} key={item}>
                {item}
              </TH>
            ))
          )}
        </TR>
      </>
    )
    return tableHead
  }

  const makeTableData = () => {
    let data = []
    let _arr = reporteeList
    _arr?.forEach((item, i) => {
      let obj = {}
      obj['s.no'] = i + 1
      obj['Employee Name'] = item.operating_asset_first_name
      obj['Employee Code'] = item.operating_asset_customer_unique_id
      obj['Employee Email'] = !isAccountManager
        ? item.operating_asset_email_id
        : '-'
      obj['Role'] = !isAccountManager ? item.asset_type_name : '-'
      obj['Segment'] = !isAccountManager ? item.workforce_tag_name : '-'
      obj['Circle'] = !isAccountManager ? item.account_name : '-'
      obj['Cluster'] = !isAccountManager ? item.cluster_tag_name : '-'
      obj['Vertical'] = !isAccountManager ? item.asset_vertical_tag_name : '-'
      obj['Employees on SIP'] = !isAccountManager
        ? item.sip_reportee_count
        : '-'
      obj['Qualified for SIP'] = !isAccountManager
        ? item.sip_qualified_reportee_count
        : '-'
      obj['SIP Penetration %'] = !isAccountManager
        ? item.penetration_percent
        : '-'
      obj['SIP Utilization %'] = !isAccountManager
        ? item.utilization_percent
        : '-'
      obj['Weighted SIP Target vs Achievement %'] = !isAccountManager
        ? item.weighted_sip_target_ach_percent
        : '-'

      sipWidgetList?.forEach((data, i) => {
        let parentHead =  reporteeList.filter(el => {
             return el.asset_id === data.asset_id
       });
        if (data.asset_id === parentHead.asset_id && !!sipWidgetList?.filter(e =>{
          return e.widget_type_code === data.widget_type_code
        })
        )
        {
          obj[
            `${parentHead.activity_title}-${parentHead.activity_type_id}-Target`
          ] = data.monthly_target
          obj[
            `${parentHead.activity_title}-${parentHead.activity_type_id}-Achievement Final`
          ] = data.monthly_ach
          obj[
            `${parentHead.activity_title}-${parentHead.activity_type_id}-Payout Final`
          ] = data.percentage
        }
      })
      data.push(obj)
    })
    return [data, _arr]
  }

  const renderData = () => {
    let [data, _arr] = makeTableData()
    return data.map((item, i) => (
      <TR key={i}>
        {Object.values(item).map((val, j) =>
          j === 1 ? (
            <TD
              key={j}
              onClickHandler={() => loadResourceForAsset(_arr[i])}
              classes={`text-bold green-theme cursor-pointer`}
            >
              {val}
            </TD>
          ) : (
            <TD key={j}>{val}</TD>
          )
        )}
      </TR>
    ))
  }

  const makeSelfData = () => {
    let heads = [
      isSelf
        ? 'Self'
        : isAccountManager
        ? !!queryFilters.selectedSipSegment &&
          queryFilters.selectedSipSegment.value === 184
          ? 'Channel Name'
          : 'Account Name'
        : 'Employee Name',
      isAccountManager
        ? !!queryFilters.selectedSipSegment &&
          queryFilters.selectedSipSegment.value === 184
          ? 'Channel Code'
          : 'Account Code'
        : 'Employee Code',
      'Employee Email',
      'Role',
      'Segment',
      'Circle',
      'Cluster',
      'Vertical',
      'Employees on SIP',
      'Qualified for SIP',
      'SIP Penetration %',
      'SIP Utilization %',
      'Weighted SIP Target vs Achievement %',
    ]
    let keys = {
      Self: 'operating_asset_first_name',
      'Account/Channel Name': 'operating_asset_first_name',
      'Employee Name': 'operating_asset_first_name',
      'Account/Channel Code': 'operating_asset_customer_unique_id',
      'Employee Code': 'operating_asset_customer_unique_id',
      'Employee Email': 'operating_asset_email_id',
      Role: 'asset_type_name',
      Segment: 'workforce_tag_name',
      Circle: 'account_name',
      Cluster: 'cluster_tag_name',
      Vertical: 'asset_vertical_name',
      'Employees on SIP': 'sip_reportee_count',
      'Qualified for SIP': 'sip_qualified_reportee_count',
      'SIP Penetration %': 'penetration_percent',
      'SIP Utilization %': 'utilization_percent',
      'Weighted SIP Target vs Achievement %': 'weighted_sip_target_ach_percent',
    }
    let obj = {}
    if (managerDetails) {
      heads.forEach(head => {
        obj[head] = managerDetails[keys[head]]
      })
    }
    return [obj]
  }

  const renderSelfData = () => {
    if (!!managerDetails) {
      return [
        <TR>
          <TD>1</TD>
          <TD classes={`text-bold green-theme`}>
            {managerDetails.operating_asset_first_name}
          </TD>
          <TD>{managerDetails.operating_asset_customer_unique_id}</TD>
          <TD>{managerDetails.operating_asset_email_id}</TD>
          <TD>{managerDetails.asset_type_name}</TD>
          <TD>{managerDetails.workforce_tag_name}</TD>
          <TD>{managerDetails.account_name}</TD>
          <TD>{managerDetails.cluster_tag_name}</TD>
          <TD>{managerDetails.asset_vertical_name}</TD>
          <TD>{managerDetails.sip_reportee_count}</TD>
          <TD>{managerDetails.sip_qualified_reportee_count}</TD>
          <TD>{managerDetails.penetration_percent}</TD>
          <TD>{managerDetails.utilization_percent}</TD>
          <TD>{managerDetails.weighted_sip_target_ach_percent}</TD>
          {!!sipWidgetList.manager_data
            ? sipWidgetList.manager_data.map((data, i) => {
                if (
                  sipWidgetList?.manager_kpi.find(
                    kpi => kpi.activity_type_id === data.activity_type_id
                  )
                ) {
                  return [
                    <TD key={i + 1}>{data.target}</TD>,
                    <TD key={i + 2}>{data.achieved}</TD>,
                    <TD key={i + 4}>
                      {!!data.percentage && data.percentage.toFixed(2)}
                    </TD>,
                  ]
                }
              })
            : null}
        </TR>,
      ]
    }
  }

  const handleDownload = () => {
    setDownload(true)
  }

  const exportHeadings = self => {
    let heads = [
      self
        ? 'Self'
        : isAccountManager
        ? !!queryFilters.selectedSipSegment &&
          queryFilters.selectedSipSegment.value === 184
          ? 'Channel Name'
          : 'Account Name'
        : 'Employee Name',
      isAccountManager
        ? !!queryFilters.selectedSipSegment &&
          queryFilters.selectedSipSegment.value === 184
          ? 'Channel Code'
          : 'Account Code'
        : 'Employee Code',
      'Employee Email',
      'Role',
      'Segment',
      'Circle',
      'Cluster',
      'Vertical',
      'Employees on SIP',
      'Qualified for SIP',
      'SIP Penetration %',
      'SIP Utilization %',
      'Weighted SIP Target vs Achievement %',
    ]

    let subHeads = ['Target', 'Achievement Final', 'Payout Final']

    let dynamicHeads = sipWidgetList?.[
      self ? 'manager_kpi' : 'reportee_kpi'
    ]?.map(header =>
      subHeads.map(
        sub => `${header.activity_type_name}-${header.activity_type_id}-${sub}`
      )
    )
    heads = heads.concat(_.flattenDeep(dynamicHeads))
    return heads
  }

  const renderExportData = () => {
    let dataSet = makeTableData()
    let selfData = makeSelfData()

    const fileName = 'SIP_Download'
    return (
      <ExcelFile filename={fileName} hideElement={true}>
        {[
          { sheet: 'SIP Data', data: dataSet, heads: exportHeadings() },
          { sheet: 'Self Data', data: selfData, heads: exportHeadings(true) },
        ].map(item => (
          <ExcelSheet data={item.data} name={item.sheet}>
            {item.heads?.map(headerItem => (
              <ExcelColumn label={headerItem} value={headerItem} />
            ))}
          </ExcelSheet>
        ))}
      </ExcelFile>
    )
  }

  const widgetMainView =
    isLoading || isChannelLoading || isSipWidgetDataLoading ? (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    ) : (
      <Box>
        <Flex mb={3} p={3} alignItems="center">
          <Icon
            as={BiDownload}
            w={6}
            h={6}
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DASHBOARD,
                action: 'Widget SIP Payout View',
                label: 'Download',
              })
              handleDownload()
            }}
            className={`cursor-pointer`}
          />
          &nbsp;&nbsp;
          {download && renderExportData()}
          {!isAccountManager &&
            !!sipReporteeList &&
            sipReporteeList.map((item, i) => (
              <>
                {i !== 0 ? '/' : null}
                <Text
                  fontWeight="bold"
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_DASHBOARD,
                      action: 'Widget SIP Payout View',
                      label: 'Load Resource For Asset',
                    })
                    loadResourceForAsset(item)
                  }}
                  color={localStorage.getItem('color')}
                  cursor="pointer"
                  textDecoration="underline"
                >
                  {' '}
                  {item.operating_asset_first_name}{' '}
                </Text>
              </>
            ))}
        </Flex>
        {isSipWidgetDataSuccess ? (
          <VStack spacing={8}>
            {isSelf ? (
              <>
                <DrillDownTable
                  theadings={renderHeadings(true)}
                  tdata={renderSelfData()}
                />
                <Divider />
              </>
            ) : null}
            <DrillDownTable theadings={renderHeadings()} tdata={renderData()} />
          </VStack>
        ) : null}
      </Box>
    )

  return (
    <Box bg="white" rounded="md" p="8px">
      {widgetMainView}
    </Box>
  )
}

export default WidgetSIPPayoutView
