import React, { useEffect, useState } from 'react'
import {
    useLoadTataPlaySummary,
    useLoadTataPlayNetChurnList,
    useLoadTataPlayNetAddList,
    useLoadTataPlayChurnWidget,
    useLoadTataPlayAddWidget,
    useLoadTataPlayOpenBaseList,
    useLoadTataPlayCloseBaseList
} from '../../../../hooks/dashboard.hooks'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { numberWithCommas } from '../../../../utils/common.util'
import { PropagateLoader } from 'react-spinners'
import { Tooltip, Flex, Box, Button, Text, Icon } from '@chakra-ui/react'
import DrillDownTable from '../../../../components/dashboard-table-grid'
import { BiDownload } from 'react-icons/bi'
import ReactExport from 'react-data-export'
import ReactFusioncharts from 'react-fusioncharts'
import { getFormatDDMMYYYY } from '../../../../utils/date.utils'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const TataPlaySummeryView = () => {
    const [formData, setFormData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [netChurnData, setnetChurnData] = useState([]);
    const [netAddData, setnetAddData] = useState([]);

    const [netChurnWidgeData, setnetChurnWidgeData] = useState([]);
    const [netAddWidgeData, setnetAddWidgeData] = useState([]);

    const [openBaseList, setopenBaseList] = useState([]);
    const [closeBaseList, setcloseBaseList] = useState([])
    const { mutate: loadTataPlaySummary } = useLoadTataPlaySummary()
    const { mutate: loadTataPlayNetChurn } = useLoadTataPlayNetChurnList();
    const { mutate: loadTataPlayNetAdd } = useLoadTataPlayNetAddList();
    const { mutate: loadTataPlayChurnWidget } = useLoadTataPlayChurnWidget()
    const { mutate: loadTataPlayAddWidget } = useLoadTataPlayAddWidget()
    const { mutate: loadTataPlayOpenBaseList } = useLoadTataPlayOpenBaseList()
    const { mutate: loadTataPlayCloseBaseList } = useLoadTataPlayCloseBaseList()
    const {
        state: { queryFilters },
        dispatch: dashboardDispatch,
    } = useDashboardContext()

    useEffect(() => {
        toLoadTataPlayData()
        if (queryFilters?.selectedCluster?.value === 0) {
            toLoadTataPlayPieChartData();
            toLoadTataPlayOpenAndCloseBaseList();
        }
        toLoadTataPlayLineChartData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryFilters.endDateTime, queryFilters.selectedCity, queryFilters.selectedCluster])

    const toLoadTataPlayData = () => {
        setisLoading(true)
        loadTataPlaySummary(
            {
                summary_start_date: queryFilters.endDateTime,
                summary_end_date: queryFilters.endDateTime,
                city_tag_id: queryFilters?.selectedCluster?.value || 0,
            },
            {
                onSuccess: async data => {
                    setFormData(data)
                    setisLoading(false)
                },
            }
        )
    }

    const toLoadTataPlayPieChartData = () => {
        loadTataPlayNetChurn({ date_selected: queryFilters.endDateTime }, {
            onSuccess: async (data) => {
                setnetChurnData(data)
            }
        })

        loadTataPlayNetAdd({ date_selected: queryFilters.endDateTime }, {
            onSuccess: async (data) => {
                setnetAddData(data)
            }
        })

    }

    const toLoadTataPlayOpenAndCloseBaseList = () => {
        loadTataPlayOpenBaseList({ date_selected: queryFilters.endDateTime }, {
            onSuccess: async (data) => {
                setopenBaseList(data)
            }
        })

        loadTataPlayCloseBaseList({ date_selected: queryFilters.endDateTime }, {
            onSuccess: async (data) => {
                setcloseBaseList(data)
            }
        })

    }


    const toLoadTataPlayLineChartData = () => {
        loadTataPlayChurnWidget({
            "date_selected": queryFilters.endDateTime,
            "flag": queryFilters?.selectedCluster?.value || 0,//remove once backend changes done
            "city_tag_id": queryFilters?.selectedCluster?.value || 0,

        }, {
            onSuccess: data => {
                setnetChurnWidgeData(data)
            }
        })
        loadTataPlayAddWidget({
            "date_selected": queryFilters.endDateTime,
            "flag": queryFilters?.selectedCluster?.value || 0,//remove once backend changes done
            "city_tag_id": queryFilters?.selectedCluster?.value || 0,

        }, {
            onSuccess: data => {
                setnetAddWidgeData(data)
            }
        })
    }
    const renderHeadings = () => {
        let firstHeader = [
            {
                header_name: 'Sl No.',
                db_field_name: "slNo",
                width:'100',
            },
            {
                header_name: 'Particulars',
                db_field_name: "kpi_name",
                width:'150',
            },
            {
                header_name: 'FTD',
                db_field_name: "kpi_value_ftd",
                width:'150',
            },
            {
                header_name: 'Last 7 Days average',
                db_field_name: "kpi_value_last_7_days_average",
                width:'150',
            },
            {
                header_name: 'WTD',
                db_field_name: "kpi_value_wtd",
                width:'150',
            },
            {
                header_name: 'LWTD',
                db_field_name: "kpi_value_lwtd",
                width:'150',

            },
            {
                header_name: 'MTD',
                db_field_name: 'kpi_value_mtd',
                width:'150',
            },
        ]

        return firstHeader


    }

    const makeTableData = () => {
        return formData.map((item, i) => {
            return {
                ...item,
                slNo: i + 1,
                kpi_value_wtd: item.kpi_value_wtd === '0' && ['Opening Base', 'Closing Base'].includes(item.kpi_name) ? 'NA' : item.kpi_value_wtd,
                kpi_value_lwtd: item.kpi_value_lwtd === '0' && ['Opening Base', 'Closing Base'].includes(item.kpi_name) ? 'NA' : item.kpi_value_lwtd,
                kpi_value_mtd: item.kpi_value_mtd === '0' && ['Opening Base', 'Closing Base'].includes(item.kpi_name) ? 'NA' : item.kpi_value_mtd
            }
        })
        
    }


    const getDataForExport = (header, accountData, xstep = 0, ystep = 0) => {
        let obj = {
            xSteps: xstep,
            ySteps: ystep,
            columns: header.map(item => ({ title: item.title })),
            data: accountData.map(item => {
                return header.map(header => ({
                    value: numberWithCommas(item[header.value]) || '',
                }))
            }),
        }
        return obj
    }
    const createExcel = () => {
        const result = []
        const accountData = makeTableData()

        result.push(
            getDataForExport(
                [
                    {
                        title: 'Particulars',
                        value: "kpi_name"
                    },
                    {
                        title: 'FTD',
                        value: "kpi_value_ftd"
                    },
                    {
                        title: 'Last 7 Days average',
                        value: "kpi_value_last_7_days_average"
                    },
                    {
                        title: 'WTD',
                        value: "kpi_value_wtd"
                    },
                    {
                        title: 'LWTD',
                        value: "kpi_value_lwtd"
                    },
                    {
                        title: 'MTD',
                        value: 'kpi_value_mtd'
                    },
                ],
                accountData,
                0,
                3
            )
        )
        return result
    }


    const toRenderChartData = (data = [], name, type) => {
        let dataSource = {}
        let typeOf = 'Volume'

        dataSource.chart = {
            caption: name + `${type === 'line' ? " - Trend" : " - City Wise"}`,
            captionFontColor: '#718096',
            plottooltext: '<b>$label</b> :- <b>$value</b>',
            bgColor: '#FFFFFF',
            showvalues: '1',
            showPercentValues: '0',
            labelFontColor: '#000',
            usedataplotcolorforlabels: '1',
            theme: 'fusion',
            smartLineColor: '#009B76',
            smartLineThickness: '1',
            showLegend: '0',
            widgetValueType: typeOf,
        }
        let filerData = []
        filerData = (data || []).map(val => {
            return {
                value: Number(type === 'line' ? val?.kpi_value_wtd : val?.kpi_value_ftd),
                label: type === 'line' ? getFormatDDMMYYYY(val?.summary_date) : val?.city_tag_name
            }
        })
        dataSource.data = filerData;

        return dataSource;
    }
    return (
        <>

            <Box>
                <Flex
                    mb={0}
                    p={3}
                    alignItems="flex-start"
                    justifyContent='flex-end'
                    w="100%"
                    position="sticky"
                    left={0}
                >
                    <div
                        style={
                            formData.length === 0
                                ? {
                                    cursor: 'not-allowed',
                                }
                                : {}
                        }
                    >
                        <div
                            style={
                                formData.length === 0
                                    ? {
                                        pointerEvents: 'none',
                                        opacity: '0.4',
                                    }
                                    : {}
                            }
                        >
                            <ExcelFile
                                filename={`Account_View_Report`}
                                element={
                                    <Button mr={2}>
                                        <Icon as={BiDownload} w={6} h={6} mr={1} />
                                        <Text fontWeight="bold" color={localStorage.getItem('color')}>
                                            Export Data
                                        </Text>
                                    </Button>
                                }
                            >
                                <ExcelSheet
                                    dataSet={createExcel()}
                                    name="Organization"
                                ></ExcelSheet>
                            </ExcelFile>
                        </div>
                    </div>
                </Flex>

                <DrillDownTable
                    tableHeaderList={renderHeadings()}
                    tableData={makeTableData()}
                    isLoading={isLoading}
                    allowSorting={false}
                    allowFiltering={false}
                />
            </Box>

            <Box mt='5'>
                <ReactFusioncharts
                    type="line"
                    width="98%"
                    height="400"
                    dataFormat="JSON"
                    dataSource={toRenderChartData(netChurnWidgeData, 'Gross Churn', 'line')}
                //   events={onChartClickEvent()}
                />

                <ReactFusioncharts
                    type="line"
                    width="98%"
                    height="400"
                    dataFormat="JSON"
                    dataSource={toRenderChartData(netAddWidgeData, 'Gross Adds', 'line')}
                //   events={onChartClickEvent()}
                />
            </Box>

            {
                queryFilters?.selectedCluster?.value === 0 &&
                <>
                    <Flex justifyContent='space-around' mt='5'>
                        <ReactFusioncharts
                            type="pie2d"
                            width="48%"
                            height="400"
                            dataFormat="JSON"
                            dataSource={toRenderChartData(netChurnData, 'Gross Churn', 'pie')}
                        //   events={onChartClickEvent()}
                        />

                        <ReactFusioncharts
                            type="pie2d"
                            width="48%"
                            height="400"
                            dataFormat="JSON"
                            dataSource={toRenderChartData(netAddData, 'Gross Adds', 'pie')}
                        //   events={onChartClickEvent()}
                        />
                    </Flex>

                    <Flex justifyContent='space-around' mt='5'>
                        <ReactFusioncharts
                            type="pie2d"
                            width="48%"
                            height="400"
                            dataFormat="JSON"
                            dataSource={toRenderChartData(openBaseList, 'Opening Base', 'pie')}
                        //   events={onChartClickEvent()}
                        />

                        <ReactFusioncharts
                            type="pie2d"
                            width="48%"
                            height="400"
                            dataFormat="JSON"
                            dataSource={toRenderChartData(closeBaseList, 'Closing Base', 'pie')}
                        //   events={onChartClickEvent()}
                        />
                    </Flex>
                </>

            }
        </>
    )
}

export default TataPlaySummeryView
